import React from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import Loadable from "react-loadable";

import ProtectedRoute from "./pages/protectedRoutes/protectedRoute";

//import "./App.css";

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

// Containers
const Home = Loadable({
  loader: () => import("./pages/home"),
  loading
});

//Login page
const Login = Loadable({
  loader: () => import("./pages/login"),
  loading
});
const SignUp = Loadable({
  loader: () => import("./pages/signup"),
  loading
});

function App() {
  
  return (
    <HashRouter>
      <Switch>
        <Route exact path="/login" name="Login Page" component={Login} />
        <Route exact path="/signUp" name="SignUp Page" component={SignUp} />
        <ProtectedRoute path="/" name="Home page" component={Home} />
      </Switch>
    </HashRouter>
  );
}

export default (App);

